import React from "react";

const UpdateStateContext = React.createContext({});

export default UpdateStateContext;
/*
Note that the object does NOT really matter here
We'll explictly set the real object inside Provider

It's just a good practice to set the defaul or initial object
*/
