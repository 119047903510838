import React from "react";

const DisplayThemeContext = React.createContext({});

export default DisplayThemeContext;
/*
Note that the object does NOT really matter here
We'll explictly set the real object inside Provider

It's just a good practice to set the defaul or initial object
*/
